<template>
  <div class="hero is-fullheight is-relative">
      <div class="hero-head absolute">
        <Navbar @dark="changeDark"></Navbar>
      </div>
      <div class="hero-body" data-aos="fade-right">
        <div class="container">
            <h1 class="heading-text">Chciał*byś<span class="primary"> animację lub montaż</span> za niską cenę?</h1>
            <div class="descContainer mt-2">
                <a class="btn" :href="link=('https://www.youtube.com/watch?v=kIBMLhvxJgE')" target="_blank">Coś o nas!</a>
                <a class="ic" :href="getENV('TIKTOK')" target="_blank"><font-awesome-icon icon="fa-brands fa-tiktok"></font-awesome-icon></a>
                <a class="ic" :href="link=('https://discord.gg/HPZQAQyV2Y')" target="_blank"><font-awesome-icon icon="fa-brands fa-discord"></font-awesome-icon></a>
            </div> 
        </div>
       </div>
        <div class="head-foot is-clickable">
            <font-awesome-icon icon="fa-solid fa-angle-down"  class="angleDown"
                @click="scrollTo('zaufaliBox')">
            </font-awesome-icon>
        </div>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import { getENV, scrollTo } from '@/utils.js'

export default {
    components: {
        Navbar
    }, methods: {
        getENV, scrollTo,
        changeDark(value) {
            this.$emit('dark', value);
        }
    }
}
</script>

<style scoped lang="scss">
@import "~bulma/sass/utilities/mixins";

.storm {
    position: absolute;
    z-index: 1;
    top: 35%;
    right: 10%;
    transform: translateY(-40%) scale(.50);

    @include until-fullhd {
        z-index: 0;
        opacity: .3;
    }
}

.btn {
  padding: 9px 40px;
  background-color: rgb(187 71 255);
  color: #ffffff;
  font-weight: 800;
  font-size: 1.65rem;
  border-radius: 48px;
  box-shadow: 0 0 0 0 rgb(204 0 255 / 60%);
  -webkit-animation: pulse 5s infinite;

    transition: background-color 0.1s;

  &:hover {
    background-color: #d22fff;
    color: rgb(255, 255, 255);
    box-shadow: #d549fc;
    -webkit-animation: pulse 5s infinite;
  }

  @include until-widescreen {
    background-color: var(--primary);
    color: rgb(0, 0, 0);
  }
}

.heading-text {
    font-size: 2rem;
    color: rgb(255, 255, 255);
    font-weight: 750;
    font-family: "Poppins", sans-serif;
    width: 70%;

    @include touch {
        // font-size: 1.25rem;
        // font-size: 1.5rem;
        // font-size: 2vw;
        width: 70%;
        font-size: clamp(1.5rem, 4vw, 2rem);
    }

    @include mobile {
        width: 100%;
    }
}


.descContainer {
    display: flex;
    align-items: center;
    gap: 20px;
}

.ic {
    color: #c1c1c1;
    font-size: 2.25rem;

    @include touch {
        font-size: 1.8rem;
    }
}

.absolute {
    position: absolute;
    width: 100%;
    z-index: 3;
}

.angleDown {
    position: absolute;
    left: 0;
    right:0;
    margin: 0 auto;
    
    font-size: 3.25rem;
    color: var(--primary);
    bottom: 20px;

    cursor: pointer;
    animation: bounce 2s infinite;
    z-index: 10;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(-35px);
  }

  40% {
    transform: translateY(-15px);
  }

  60% {
    transform: translateY(-20px);
  }
}

.hero-body {
    z-index: 1;
    position: relative;
}
</style>
