import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import events from '../views/Event.vue'
//import partner from '../views/partner.vue'
//import Forms from '../views/forms.vue'
//import command from '../views/Spis.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/wiadomosci',
    name: 'events',
    component: events
  },
  {path: "*", redirect: "/" }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
