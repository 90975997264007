<template>
    <div class="card">
      <img class="image" :src="avatar" />
      <span class="nickname">{{ nickname }}</span>
      <span class="description">{{ description }}</span>
    </div>
  </template>
  
  <script>
  export default {
    name: "Opinion",
    props: {
      avatar: String,
      nickname: String,
      description: String
    }
  }
  </script>
  
  <style scoped>
  
  .card {
    background-color: #101010;
    color: white;
    border-radius: 41px;
    padding: 62px;

    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .5s ease;
    flex-direction: column;
  
    gap: 5px;
  
    min-width: 150px;
    max-width: 300px;

    -webkit-box-shadow: 4px 7px 100px -51px rgba(206, 0, 255, 1);
-moz-box-shadow: 4px 7px 100px -51px rgba(206, 0, 255, 1);
box-shadow: 4px 7px 100px -51px rgba(206, 0, 255, 1);
  }

  .card:hover {
  transform: scale(1.15);
}

  .image {
    border-radius: 50%;
    width: 6rem  
  }
  
  .nickname {
    font-weight: 700;
    font-size: 1.5rem;
  }
  
  .description {
    font-weight: 400;
    font-size: 1rem;
  }
  </style>