<template>
  <div class="is-relative" style="overflow: hidden" id='website'>
    <div :class="['darkLayer', {'active' : dark}]"></div>
    <div class="decorationContainer">
    </div>
    <Header @dark="changeDark"></Header>
    <section class="section" id="zaufaliNam" data-aos="fade-down">
      <div class="container has-text-centered">
        <h1 class="sectionTitle mb-6">Zaufali nam</h1>
        <div class="is-flex is-justify-content-center">
          <Carousel :items="items"></Carousel>
        </div>
      </div>
    </section>
    <section class="section" id="zaufaliNam" data-aos="fade-down">
      <div class="container has-text-centered">
        <h1 class="sectionTitle mb-6">Opinie</h1>
        <div class="is-flex is-justify-content-center">
          <div class="columns">
            <div class="column" v-for="opinion in opinions" :key="opinion">
              <Opinion 
          :avatar="opinion.avatar"
          :nickname="opinion.nickname"
          :description="opinion.description"
      />
            </div>
    </div>
        </div>
      </div>
    </section>
   <section>
    
 </section>
    <section style="margin-bottom: 6rem" data-aos="zoom-in">
      <div class="container has-text-centered">
        <a class="btn" :href="getENV('ME')" target="_blank">Złóż zamówienie!</a>
      </div>
    </section>
    <Footer name="pimpus.ml" color="#fad61d"></Footer>
  </div>
  
</template>

<script>
import Header from '@/components/Header'
import Carousel from '../components/Carousel.vue'
import Footer from '../components/Footer.vue'
import Opinion from '../components/Opinion.vue'
import { getENV } from '@/utils.js'

export default {
  name: 'Home',
  components: {
    Header,
    Carousel,
    Footer,
    Opinion 
  }, data() {
    return {
      items: [
        {img: 'https://cdn.discordapp.com/attachments/1031595316652281956/1045378579107696670/Jasio.png', name: 'Jasio'},
        {img: 'https://cdn.discordapp.com/attachments/1031595316652281956/1045378578767949854/Rayer.png', name: 'Rayer'},
        {img: 'https://cdn.discordapp.com/attachments/1031595316652281956/1045378578478534857/koszak.webp', name: 'Koszak'},
        {img: 'https://cdn.discordapp.com/attachments/1031595316652281956/1045731658210476092/OLI11GH.png', name: 'OLI11GH'},
        {img: 'https://pimpus.ml/pimpus.png', name: 'Twoja nazwa'},
      ], dark: false,
      opinions: [
        {
          avatar: "https://cdn.discordapp.com/attachments/1031595316652281956/1045378578478534857/koszak.webp",
          nickname: "Koszak",
          description: "Reklama wykonana dobrze, mało opisów ale z mojej winy, ogólnie podoba się ~ Eturn. Owner"
        },
        {
          avatar: "https://cdn.discordapp.com/attachments/1031595316652281956/1045731658210476092/OLI11GH.png",
          nickname: "OLI11GH",
          description: "Reklama wykonana w dobrej jakości i szybko oraz tanio. Polecam"
        },
        
      ]
    }
  }, methods: {
    changeDark(value) {
      this.dark = value;
    }, getENV
  }
}
</script>

<style scoped lang="scss">
@import "~bulma/sass/utilities/mixins";

#website {
  background-color: #1b1b1b;
}

.btn {
  padding: 9px 40px;
  background-color: rgb(187 71 255);
  color: #ffffff;
  font-weight: 800;
  font-size: 1.65rem;
  border-radius: 48px;
  box-shadow: 0 0 0 0 rgb(204 0 255 / 60%);
  -webkit-animation: pulse 5s infinite;

    transition: background-color 0.1s;

  &:hover {
    background-color: #d22fff;
    color: rgb(255, 255, 255);
    box-shadow: #d549fc;
    -webkit-animation: pulse 5s infinite;
  }

  @include until-widescreen {
    background-color: var(--primary);
    color: rgb(0, 0, 0);
  }
}

.decorationContainer {
  position: absolute; 
  width: 1800px; 
  left: 50%;
  transform: translateX(-50%); 
  top: 0; 
  bottom: 0;
}

.botInfo {
  display: block;
  .iconn {
    font-size: 2rem;
    color: var(--opinia_icon)
  }

  .header-opinia {
    color: var(--primary);
    font-size: 1.5rem;
    font-weight: 800;
  }

  .desc-opinia {
    color: #ffffff;
    font-weight: 400;
    font-size: 1.05rem;
  }
}

.aboutDev {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;

  @include touch {
    gap: 25px;
    flex-direction: column;
  }
  // margin-left: auto;
  // margin-right: auto;

  img {
    border-radius: 50%;
    border: var(--primary) solid 3px;
    height: 200px;

    @include touch {
      // height: clamp(100px, 15vw, 175px);
      height: clamp(120px, 20vw, 175px);
      // height: 18vw;
    }
  }

  span {
      white-space: pre-wrap;
      color: #fff;
      font-size: 1.2rem;
      font-weight: 600;

      @include touch {
        font-size: clamp(1rem, 3vw, 1.25rem);
        // font-size: 2vw;
        // font-size: 1.25rem;
        // font-size: 1rem;
      }
  }

  a {
    transition: color .2s;

    &:hover {
      color: var(--primary)
    }
  }
}

.section {
  margin-bottom: 15rem;
  // z-index: 0;
  // position: relative;

  @include touch {
    margin-bottom: 5rem;
  }
}

.sectionTitle {
  font-size: clamp(2rem, 8vw, 2.5rem);
  color: #fff;
  font-weight: 800;
  // margin-bottom: 1rem;
  // font-size: 8vw;
  // font-size: 2.5rem;
  // font-size: 2rem;
}

.sectionTitleOpinia {
  font-size: clamp(2rem, 8vw, 2.5rem);
  color: #fff;
  font-weight: 800;
  // margin-bottom: 1rem;
  // font-size: 8vw;
  // font-size: 2.5rem;
  // font-size: 2rem;
}

.darkLayer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -5;

  backdrop-filter: brightness(100%) opacity(0);

  transition: z-index .5s step-end, backdrop-filter .5s linear;

  &.active {
    z-index: 3;
    backdrop-filter: brightness(50%) opacity(1);

    transition: z-index .5s step-start, backdrop-filter .5s linear;
  }
}
</style>
