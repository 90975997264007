<template>
  <div class="is-relative" style="overflow: hidden" id='website'>
    <Navbar @dark="changeDark"></Navbar>
    <section class="section" id="zaufaliNam" data-aos="fade-down">
      <div class="container has-text-centered">
        <h1 class="sectionTitle mb-6">Wiadomości</h1>
        <div class="columns is-multiline">
          <div class="column is-4">
            <Event title="Black friday" description= " -45% do jutra ( 25 listopada ) z racji, że jest black friday " avatar="https://pimpus.ml/pimpus.png" date="24.11.2022" />
          </div>
          <div class="column is-4">
            <Event title="Partnerstwo" description="By nawiązać partnerstwo prosimy o stworzenie ticketa na naszym discordzie. " avatar="https://pimpus.ml/pimpus.png" date="15.11.2022" />
          </div>
        </div>
      </div>
    </section>
    <Footer name="pimpus.ml" color="#fad61d"></Footer>
  </div>
  
</template>

<script>
//import Carousel from '../components/Carousel.vue'
import Footer from '../components/Footer.vue'
//import Opinion from '../components/Opinion.vue'
//import Card from '../components/Event.vue'
import Navbar from '../components/Navbar.vue'
import Event from '../components/Event.vue'
import { getENV } from '@/utils.js'

export default {
  name: 'Home',
  components: {
    //Carousel,
    Footer,
    //Opinion,
    //Card,
    Navbar,
    Event
  }, data() {
    return {
      items: [
        {img: 'https://avatars.githubusercontent.com/u/91021785?v=4?s=400', name: 'Jasio'},
        {img: 'https://cdn.discordapp.com/avatars/842378070857875497/a_e34f0bba1aa1693f3511b2004cbf042c.gif', name: 'Rayer'},
        {img: 'https://cdn.discordapp.com/avatars/496924702955012117/e5e84803f87190a1df56eca142a71f33.webp', name: 'Koszak'},
        {img: 'https://pimpus.ml/pimpus.png', name: 'Twoja nazwa'},
        {img: 'https://pimpus.ml/pimpus.png', name: 'Twoja nazwa'},
      ], dark: false,
      opinions: [
        {
          avatar: "https://cdn.discordapp.com/avatars/496924702955012117/e5e84803f87190a1df56eca142a71f33.webp",
          nickname: "Koszak",
          description: "Reklama wykonana dobrze, mało opisów ale z mojej winy, ogólnie podoba się ~ Eturn. Owner"
        },
      ],
      events: [
        {
          avatar: "https://pimpus.ml/pimpus.png",
          title: "Halloween",
          description: "-15% z kodem Hallowen (kod mozna wykorzystac do dnia 01.11.2022 i do godziny 19:00 )",
          date: "30.10.2022"
        },
        {
          avatar: "https://pimpus.ml/pimpus.png",
          title: "Partnerstwo",
          description: "By nawiązać partnerstwo prosimy o stworzenie ticketa na naszym discordzie.",
         date: "31.10.2022"
        },
      ]
    }
  }, methods: {
    changeDark(value) {
      this.dark = value;
    }, getENV
  }
}
</script>

<style scoped lang="scss">
@import "~bulma/sass/utilities/mixins";

#website {
  background-color: #1b1b1b;
  min-height: 100vh;
}


.decorationContainer {
  position: absolute; 
  width: 1800px; 
  left: 50%;
  transform: translateX(-50%); 
  top: 0; 
  bottom: 0;
}


.botInfo {
  display: block;
  .iconn {
    font-size: 2rem;
    color: var(--opinia_icon)
  }

  .header-opinia {
    color: var(--primary);
    font-size: 1.5rem;
    font-weight: 800;
  }

  .desc-opinia {
    color: #ffffff;
    font-weight: 400;
    font-size: 1.05rem;
  }
}

.aboutDev {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;

  @include touch {
    gap: 25px;
    flex-direction: column;
  }
  // margin-left: auto;
  // margin-right: auto;

  img {
    border-radius: 50%;
    border: var(--primary) solid 3px;
    height: 200px;

    @include touch {
      // height: clamp(100px, 15vw, 175px);
      height: clamp(120px, 20vw, 175px);
      // height: 18vw;
    }
  }

  span {
      white-space: pre-wrap;
      color: #fff;
      font-size: 1.2rem;
      font-weight: 600;

      @include touch {
        font-size: clamp(1rem, 3vw, 1.25rem);
        // font-size: 2vw;
        // font-size: 1.25rem;
        // font-size: 1rem;
      }
  }

  a {
    transition: color .2s;

    &:hover {
      color: var(--primary)
    }
  }
}

.section {
  margin-bottom: 15rem;
  // z-index: 0;
  // position: relative;

  @include touch {
    margin-bottom: 5rem;
  }
}

.sectionTitle {
  font-size: clamp(2rem, 8vw, 2.5rem);
  color: #fff;
  font-weight: 800;
  // margin-bottom: 1rem;
  // font-size: 8vw;
  // font-size: 2.5rem;
  // font-size: 2rem;
}

.sectionTitleOpinia {
  font-size: clamp(2rem, 8vw, 2.5rem);
  color: #fff;
  font-weight: 800;
  // margin-bottom: 1rem;
  // font-size: 8vw;
  // font-size: 2.5rem;
  // font-size: 2rem;
}

.darkLayer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -5;

  backdrop-filter: brightness(100%) opacity(0);

  transition: z-index .5s step-end, backdrop-filter .5s linear;

  &.active {
    z-index: 3;
    backdrop-filter: brightness(50%) opacity(1);

    transition: z-index .5s step-start, backdrop-filter .5s linear;
  }
}
</style>
