<template>
    <div class="events">
      <img class="image" :src="avatar" />
      <span class="title">{{ title }}</span>
      <span class="description">{{ description }}</span>
      <span class="date">{{ date }}</span>
    </div>
  </template>
  
  <script>
  export default {
    name: "Wiadomosc",
    props: {
      avatar: String,
      title: String,
      description: String,
      date: String
    }
  }
  </script>
  
  <style scoped>
  
  .events {
       background-color: #101010;
       color: white;
       border-radius: 57px;
       padding: 52px;
       display: flex;
       justify-content: center;
       align-items: center;
       flex-direction: column;
        gap: 15px;
        max-width: 479px;
        position: relative;
        transition: all .6s ease;

        -webkit-box-shadow: -2px 3px 53px 4px rgba(26, 26, 26, 1);
        -moz-box-shadow: -2px 3px 53px 4px rgba(26, 26, 26, 1);
        box-shadow: -2px 3px 53px 4px rgba(26, 26, 26, 1);
        }

        .events:hover {
  transform: scale(1.20);
}
        
        .image {
            width: 3rem;
            right: 15px;
            bottom: 15px;
            max-width: 47px;
            border-radius: 50%;
            position: absolute;
            transition: all .5s ease-in-out;
        }

        .date {
            position: absolute;
            font-weight: 200;
            font-size: small;
            left: 30px;
            bottom: 8px;
            max-width: 53px;
            color: white;
            
        }

        .title {
            font-weight: bold;
            font-size: 2.4rem;
            color: #bb00ff;
            margin-bottom: 1.5px !important;
        }

        .description {
            font-size: 1.1rem;
            font-weight: 400;
        }

        .cards {
            display: flex;
            gap: 20px;
        }
  </style>